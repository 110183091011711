import { SET_TIME, SET_BOOKED } from './actionTypes';

const initialState = {
  timeLeft: 300, // remaining time in seconds - 5 mins
  booked: false,
};

const TimerHandler = {
  [SET_TIME]: (state, action) => {
    return {
      ...state,
      timeLeft: action.payload,
    };
  },
  [SET_BOOKED]: (state, action) => {
    return {
      ...state,
      booked: action.payload,
    };
  },
};

const timerReducer = (state = initialState, action) => {
  if (TimerHandler[action.type]) {
    return TimerHandler[action.type](state, action);
  }
  return state;
};

export default timerReducer;
