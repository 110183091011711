import { dateArrayToSchedule } from 'tutortime-filters-library/helpers/slug';
import { SET_FILTERS } from './actionTypes';

const initialState = {
  grade: null,
  subject: null,
  dates: [],
  time: '',
  groups: [],
  levels: [],
  gradesList: [],
  isRWB: false,
  weekValue: 2,
  schedule: [],
};

const setFilters = (state, filters) => {
  const newState = { ...state, ...filters };

  const { dates, time } = newState;
  if (dates.length && time) {
    const [startTime, endTime] = time.split(' - ');
    newState.schedule = dateArrayToSchedule(dates, startTime, endTime);
  }

  return newState;
};

const FiltersHandler = {
  [SET_FILTERS]: (state, action) => setFilters(state, action.payload),
};

const filtersReducer = (state = initialState, action) => {
  if (FiltersHandler[action.type]) {
    return FiltersHandler[action.type](state, action);
  }
  return state;
};

export default filtersReducer;
