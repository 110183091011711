import { FETCH_LEARNING_CENTERS } from './actionTypes';

const initialState = {
  centerList: [],
};

const handlers = {
  [FETCH_LEARNING_CENTERS]: (state, action) => {
    const newState = {
      ...state,
      centerList: action.payload,
    };

    return newState;
  },
};

const reducer = (state = initialState, action) => {
  if (handlers[action.type]) {
    return handlers[action.type](state, action);
  }
  return state;
};

export default reducer;
