import lodash from 'lodash';
import { FETCH_TUTOR_LIST, RESET_TUTOR_LIST, SET_CURRENT_USER } from './actionTypes';

const initialState = {
  items: [],
  meta: {
    currentPage: 0,
    totalPages: 1,
  },
  current: {},
  noContent: false,
};

const getItems = (old, items) => {
  const isEqual = lodash.isEqual(old, items);
  if (isEqual) {
    return items;
  }

  return [...old, ...items];
};

const TutorHandler = {
  [FETCH_TUTOR_LIST]: (state, action) => {
    const newState = {
      ...state,
      items: getItems(state.items, action.payload.items),
      meta: action.payload.meta,
    };
    if (!newState.items.length) newState.noContent = true;
    else newState.noContent = false;
    return newState;
  },
  [RESET_TUTOR_LIST]: (state, action) => ({
    ...state,
    items: action.payload || [],
    meta: {
      currentPage: 1,
      itemCount: 10,
      itemsPerPage: 10,
      totalItems: 1,
      totalPages: 2,
    },
    noContent: false,
  }),
  [SET_CURRENT_USER]: (state, action) => ({
    ...state,
    current: action.payload,
  }),

};

const tutorsReducer = (state = initialState, action) => {
  if (TutorHandler[action.type]) {
    return TutorHandler[action.type](state, action);
  }
  return state;
};

export default tutorsReducer;
