import isEqual from 'lodash/isEqual';
import {
  APPEND_BOOKING_PARAMS,
  CLEAR_BOOKING_PARAMS,
  SET_BOOKINGS,
  RESET_BOOKINGS,
} from './actionTypes';

const initialState = {
  params: [],
  list: {},
  noContent: false,
};

const getBookings = (old, current) => {
  if (!old.items) return current;

  const equal = isEqual(old.items, current.items);
  if (equal) return old;

  return {
    meta: current.meta,
    items: [...old.items, ...current.items],
  };
};

const hanlders = {
  [APPEND_BOOKING_PARAMS]: (state, action) => ({
    ...state,
    params: {
      ...state.params,
      ...action.payload,
    },
  }),
  [CLEAR_BOOKING_PARAMS]: state => ({
    ...state,
    params: [],
  }),
  [SET_BOOKINGS]: (state, action) => {
    const list = getBookings(state.list, action.payload);
    return {
      ...state,
      list,
      noContent: !list.items.length,
    };
  },
  [RESET_BOOKINGS]: state => ({
    ...state,
    list: initialState.list,
    noContent: initialState.noContent,
  }),
};

const BookingReducer = (state = initialState, action) => {
  if (hanlders[action.type]) {
    return hanlders[action.type](state, action);
  }
  return state;
};

export default BookingReducer;
