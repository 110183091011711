import {
  SET_PROFILE,
  RESET_PROFILE,
  TOGGLE_SIGN_IN_BOOKING,
  SET_LOADING,
} from './actionTypes';

const initialState = {
  profile: null,
  signInBooking: false,
  loading: true,
};

const FiltersHandler = {
  [SET_PROFILE]: (state, action) => ({
    ...state,
    profile: action.payload,
    loading: false,
  }),
  [RESET_PROFILE]: () => ({
    ...initialState,
    loading: false,
  }),
  [TOGGLE_SIGN_IN_BOOKING]: state => ({
    ...state,
    signInBooking: !state.signInBooking,
  }),
  [SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
};

const filtersReducer = (state = initialState, action) => {
  if (FiltersHandler[action.type]) {
    return FiltersHandler[action.type](state, action);
  }
  return state;
};

export default filtersReducer;
