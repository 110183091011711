import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import thunk from 'redux-thunk';
import auth from './auth/reducer';
import tutors from './tutors/reducer';
import filters from './filters/reducer';
import centers from './centers/reducer';
import booking from './booking/reducer';
import payments from './payments/reducer';
import timer from './timer/reducer';

const rootReducer = combineReducers({
  auth,
  tutors,
  filters,
  centers,
  booking,
  payments,
  timer,
});

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(...[thunk])),
);
