import React from 'react';
import { Provider, connect } from 'react-redux';
import App from 'next/app';
import { createWrapper } from 'next-redux-wrapper';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/node';
import { hotjar } from 'react-hotjar';
import 'rmc-picker/assets/index.css';

import baseTheme from 'theme';
import { store } from 'store';
import { checkAuth } from 'store/auth/actions';
import Analytics from 'helpers/analytics';
import '../styles/globals.sass';

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;

if (dsn) {
  Sentry.init({
    dsn,
    environment: environment === 'production' ? environment : 'staging',
    debug: environment !== 'production',
  });
}

Analytics.init();

class Application extends App {
  static async getInitialProps({ Component, ctx }) {
    const { headers } = ctx.req || {};
    const themeIndex = headers ? Number(headers['x-ttwl-theme']) || 0 : null;
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    return {
      pageProps,
      themeIndex,
    };
  }

  componentDidMount() {
    const { checkAuth } = this.props;
    checkAuth();
    if (typeof window !== 'undefined' && environment === 'production') {
      hotjar.initialize(hotjarId, 6);
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);

    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
      Sentry.captureException(error, { extra: errorInfo });
    }
  }

  render() {
    const { Component, pageProps, themeIndex } = this.props;
    baseTheme.themeIndex = themeIndex;
    const theme = createMuiTheme(baseTheme.theme);

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Component {...pageProps} />
          </CssBaseline>
        </ThemeProvider>
      </Provider>
    );
  }
}

const mapDispatchToProps = {
  checkAuth,
};

const wrapper = createWrapper(() => store, { debug: true });
export default wrapper.withRedux(connect(null, mapDispatchToProps)(Application));
