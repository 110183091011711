import {
  ADD_PAYMENT_METHOD,
  SAVE_PAYMENT_METHODS,
} from './actionTypes';

const initialState = {
  cards: [],
  method: null,
};

const hanlders = {
  [ADD_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    method: action.payload,
  }),
  [SAVE_PAYMENT_METHODS]: (state, action) => ({
    ...state,
    cards: action.payload,
  }),
};

const PaymentsReducer = (state = initialState, action) => {
  if (hanlders[action.type]) {
    return hanlders[action.type](state, action);
  }
  return state;
};

export default PaymentsReducer;
